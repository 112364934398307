<template>
  <div class="device-management-container">
    <div class="device-list-tools">
      <el-form class="battery-form" :inline="true" :model="formData">
        <el-form-item label="省" v-if="organId === '99'">
          <el-select
            v-model="formData.provinceCode"
            placeholder="省"
            size="small"
            class="area-form-item"
            style="width: 120px"
            clearable
            filterable
            @change="proviceHandle"
          >
            <el-option
              v-for="(item, index) in provices"
              :key="index"
              :label="item.province"
              :value="item.provinceCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" v-if="organId === '99'">
          <el-select
            v-model="formData.cityCode"
            placeholder="市"
            size="small"
            class="area-form-item"
            style="width: 120px"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in cityList"
              :key="index"
              :label="item.city"
              :value="item.cityCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input
            v-model="formData.sn"
            placeholder="设备编号"
            size="small"
            class="area-form-item"
            style="width: 220px"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="当前状态">
          <el-select
            v-model="formData.curStatus"
            placeholder="当前状态"
            size="small"
            class="area-form-item"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运维状态">
          <el-select
            v-model="formData.ompStatus"
            placeholder="运维状态"
            size="small"
            class="area-form-item"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in ompStatusList"
              :key="index"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="代理机构" v-if="organId === '99'">
          <el-select
            v-model="formData.agent"
            placeholder="代理机构"
            size="small"
            class="area-form-item"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in agentList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌" v-else>
          <el-select
            v-model="formData.brand"
            placeholder="品牌"
            size="small"
            class="area-form-item"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号" v-if="organId === '99'">
          <el-select
            v-model="formData.modelCode"
            placeholder="型号"
            size="small"
            class="area-form-item"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in pfBrandList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号" v-else>
          <el-select
            v-model="formData.model"
            placeholder="型号"
            size="small"
            class="area-form-item"
          >
           <el-option
              v-for="(item, index) in modelList"
              :key="index"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户机构:" v-if="organId === '99'">
          <el-select
            v-model="formData.userUtils"
            placeholder="用户机构"
            size="small"
            class="area-form-item"
            style="width: 120px"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in userUtilList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构:" v-else>
          <el-tree-select
            ref="group-select-tree"
            class="group-select-tree"
            v-model="formData.organId"
            :selectParams="selectParams"
            :treeParams="treeParams"
            style="width: 150px"
          >
          </el-tree-select>
        </el-form-item>
        <el-form-item style="float: right; marginright: 0px">
          <el-button type="primary" @click="search()">搜索</el-button>
          <el-button @click="resetFrom()">重置</el-button>
        </el-form-item>
        <!-- <el-row>
          <el-form-item>
            <el-button type="primary" v-if="roleId !== 2" @click="allocationHandle">设备调拨</el-button>
          </el-form-item>
        </el-row> -->
      </el-form>
      <el-button type="primary" v-if="roleId !== 2" @click="allocationHandle">设备调拨</el-button>
    </div>
    <div class="device-table-container">
      <div class="device-table-data-info">
        <img class="tips-icon" :src="require('@/assets/img/volume.png')" />
        <span>设备总量</span
        ><span style="margin: 0 10px; color: #ff0000">{{ statData.total || 0 }}</span>
        <span>待运维设备</span
        ><span style="margin: 0 10px; color: #ff0000">{{ statData.wait_omp || 0 }}</span>
        <span>异常未处理</span
        ><span style="margin: 0 10px; color: #ff0000">{{ statData.wait_pro || 0 }}</span>
        <span>售后未处理</span
        ><span style="margin: 0 10px; color: #ff0000">{{ statData.wait_back || 0 }}</span>
      </div>
      <div class="device-table-content">
        <el-table style="width: 100%" height="100%" :data="list" v-loading="loading">
          <el-table-column label="设备编号" prop="pfsn" width="250" v-if="organId === '99'">
          </el-table-column>
          <el-table-column label="设备编号" prop="sn" width="250" v-else>
          </el-table-column>
          <el-table-column label="设备品牌" prop="producer">
          </el-table-column>
          <el-table-column label="设备型号" v-if="organId === '99'">
            <template slot-scope="scope">
              {{scope.row.modelCodeText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="设备型号" prop="model" v-else>
          </el-table-column>
          <el-table-column label="所属省" v-if="organId === '99'">
            <template slot-scope="scope">
              {{scope.row.provinceCodeText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="所属市" v-if="organId === '99'">
            <template slot-scope="scope">
              {{scope.row.cityCodeText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="用户机构" v-if="organId === '99'">
            <template slot-scope="scope">
              {{scope.row.userUtilsText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="代理机构" v-if="organId === '99'">
            <template slot-scope="scope">
              {{scope.row.agentText || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="当前归属" v-else>
            <template slot-scope="scope">
              {{scope.row.organName || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="当前状态" prop="status" :formatter="statusStr"> </el-table-column>
          <el-table-column label="运维状态" prop="ompStatus" :formatter="ompStatusStr"> </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button @click="jumpDetailPage(scope.row)" type="text"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="device-table-page">
        <div class="device-table-page-container">
          <el-pagination
            background
            :current-page="formData.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="formData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Allocation v-if="visable" ref="allocation" @refreshDataList="getData" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
import moment from "moment";
import "./Index.less";
import Allocation from './components/AllocationModel'
export default {
  name: "DeviceManagement",
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visable: false,
      multipleCodes: [],
      multipleOrgIds: [],
      statusList: [],//当前状态
      ompStatusList: [],//运维状态
      brandList: [],//品牌
      modelList: [],//型号
      statData: {},//统计数据
      formData: {
        sn: null,
        brand: null,
        curStatus: null,
        model: null,
        ompStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        organId: null,
        provinceCode: null,
        cityCode: null,
        agent: null,
        userUtils: null,
        modelCode: null,
      },
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
      organId: null,
      roleId: null,
      pfBrandList: [{name: '49kWh', id: 49}, {name: '15kWh', id: 15}],
      provices: [],
      cityList: [],
      agentList: [],
      userUtilList: [],
    };
  },
  components: { Allocation },
  mounted() {
    this.organId = localStorage.getItem("organId");
    this.formData.organId = localStorage.getItem("organId");
    this.roleId = localStorage.getItem("roleId")
    this.getData();
    this.getStatuslist();
    this.getOmpStautsList()
    this.getBrandList()
    this.getModelList()
    this.getDataState()
    this.getOrgList()
    this.getProvince()
    this.getOrangeListData()
    this.getUserUtilData()
  },
  methods: {
    //获取列表数据
    getData() {
      this.loading = true
      axios
        .post(`/api/device/pageQuery`, this.formData)
        .then((res) => {
          const { list, total, page } = res;
          this.list = list;
          this.total = total;
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$message.error(err);
        });
    },
    /**获取省份数据 */
    getProvince() {
      axios
        .get(`/api/device/province`)
        .then((res) => {
          this.provices = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    /**省份选择后 */
    proviceHandle(code) {
      this.cityList = []
      axios
        .get(`/api/device/city/`+code)
        .then((res) => {
          this.cityList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    /**获取代理机构 */
    getOrangeListData() {
      axios
        .get(`/api/device/agent`)
        .then((res) => {
          this.agentList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    /**获取用户单位数据 */
    getUserUtilData() {
      axios
        .get(`/api/device/userUtils`)
        .then((res) => {
          this.userUtilList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    
    async getOrgList() {
      const rs = await axios.get(`/api/org/getOrgTree/${Number(this.organId)}`);
      this.treeParams.data = [rs];
      this.$nextTick(() => {
        this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
      });
    },
    search() {
      this.getData();
      this.getDataState()
    },
    //重置
    resetFrom() {
      this.formData = {
        sn: null,
        brand: null,
        curStatus: null,
        model: null,
        ompStatus: null,
        orderBy: null,
        pageIndex: 1,
        pageSize: 20,
        provinceCode: null,
        cityCode: null,
        agent: null,
        userUtils: null,
        modelCode: null,
        organId: localStorage.getItem("organId"),
      }
      this.getData();
      this.getDataState()
    },
    //获取当前下拉数据
    getStatuslist() {
      axios
        .post(`/api/device/select/curStatus`, {})
        .then((res) => {
          this.statusList = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    //获取运维状态数据
    getOmpStautsList() {
      axios
        .post(`/api/device/select/ompStatus`, {})
        .then((res) => {
          this.ompStatusList = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    //品牌
    getBrandList() {
      axios
        .post(`/api/device/select/brand`, {})
        .then((res) => {
          this.brandList = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    //型号
    getModelList() {
      axios
        .post(`/api/device/select/model`, {})
        .then((res) => {
          this.modelList = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    //获取统计项
    getDataState() {
      axios
        .post(`/api/device/count`, {organId: this.formData.organId})
        .then((res) => {
          this.statData = res;
        })
        .catch((err) => {
          this.$message.error(meessag);
        });
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.formData.pageIndex = val;
      this.getData();
    },
    //跳转详情
    jumpDetailPage(row) {
      this.$router.push({
        name: "DeviceDetail",
        params: {
          sn: row.sn,
          imei: row.imei,
          type: 1
        },
      });
    },
    //调拨
    allocationHandle() {
      this.visable = true;
      this.$nextTick(() => {
        this.$refs.allocation.init();
        this.$refs.allocation.title = "设备调拨";
      });
    },
    //当前状态字典
    statusStr(item) {
      if(item.status === 1 ) {
        return '正常'
      } else if( item.status === 2 ) {
        return '异常'
      } else {
        return '-'
      }
    },
    //运维状态字典
    ompStatusStr(item) {
      if(item.ompStatus === 1 ) {
        return '待处理'
      } else if( item.ompStatus === 2 ) {
        return '待回调'
      } else if( item.ompStatus === 3 ) {
        return '待售后'
      } else if( item.ompStatus === 4 ) {
        return '已完结'
      } else if( item.ompStatus === 0 ) {
        return '正常'
      } else {
        return '-'
      }
    }
  },
};
</script>