<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item label="当前机构:" :label-width="'100px'" prop="orgId">
          <el-tree-select
            ref="group-select-tree"
            class="group-select-tree"
            v-model="dataForm.orgId"
            :selectParams="selectParams"
            :treeParams="treeParams"
            @node-click="selectHandle"
            :style="{ width: '315px' }"
          >
          </el-tree-select>
        </el-form-item>
        <el-form-item label="设备编号:" :label-width="'100px'" prop="sn">
          <el-select v-model="dataForm.sn" filterable :style="{ width: '315px' }">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.sn"
              :value="item.sn"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标机构:" :label-width="'100px'" prop="organId">
          <el-tree-select
            ref="group-select-trees"
            class="group-select-tree"
            v-model="dataForm.organId"
            :selectParams="selectParams"
            :treeParams="treeParams"
            :style="{ width: '315px' }"
          >
          </el-tree-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button type="primary" @click="dataFormSubmitHandle()">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      dataForm: {
        sn: null,
        organId: null,
        orgId: null,
      },
      visible: false,
      deviceList: null,
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
      roleList: [],
      organId: null,
    };
  },
  computed: {
    rules() {
      return {
        organId: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        orgId: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        sn: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.organId = localStorage.getItem("organId");
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.getOrgList();
      });
    },
    selectHandle(item) {
      axios
        .post("/api/device/getDevices", { id: item.id })
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    async getOrgList() {
      const rs = await axios.get(`/api/org/getOrgTree/${Number(this.organId)}`);
      this.treeParams.data = [rs];
      this.$nextTick(() => {
        this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
        this.$refs["group-select-trees"].treeDataUpdateFun(
          this.treeParams.data
        );
      });
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        axios
          .post(`/api/cloudControl/transfer`, this.dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
            } else {
            }
          })
          .catch((err) => {
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>